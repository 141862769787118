import { Component } from "@angular/core";
import { AppToastService } from "../../services/toast.service";

@Component({
  selector: 'app-toasts',
  styleUrls: ["./toast.component.scss"],
  templateUrl: './toast.component.html',
})
export class AppToastsComponent {
  constructor(public toastService: AppToastService) { }
}
import { Component } from '@angular/core';
import { of } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {

  public year: number = new Date().getFullYear();

  constructor() {
  }


}

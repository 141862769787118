<nav class="pt-2 di-ai-navbar-lite">
  <div class="container di-ai-container">
    <div class="d-flex align-items-center w-100">
      <a [routerLink]="'/'" *ngIf="theme==='light'">
        <img class="d-none d-sm-block" src="../../../../assets/images/svg/Diagnose_white.svg">
        <img class="d-sm-none" src="../../../../assets/images/svg/Diagnose_white_lite.svg">
      </a>
      <a [routerLink]="'/'" *ngIf="theme==='dark'">
        <img class="d-none d-sm-block" src="../../../../assets/images/svg/Diagnose_color.svg">
        <img class="d-sm-none" src="../../../../assets/images/svg/Diagnose_color_lite.svg">
      </a>
      <span class="flex-grow-1"></span>
      <a [routerLink]="'/'" class="with-icon with-icon-right" [ngClass]="theme == 'light' ? 'link-white di-ai-icon-decline' : 'link di-ai-icon-decline-black'">Do strony głównej</a>
    </div>
  </div>
</nav>
import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class AppToastService {
  toasts: any[] = [];

  show(body: string, classname?: string, header?: string) {
    this.toasts.push({ body, header, classname });
  }

  success(body: string, header?: string) {
    this.show(body, 'bg-success text-light p-1', header);
  }

  warning(body: string, header?: string) {
    this.show(body, 'bg-warning text-light p-1', header);
  }

  error(body: string, header?: string) {
    this.show(body, 'bg-danger text-light p1', header);
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter(t => t != toast);
  }
}
<nav class="fixed-top pt-2 di-ai-navbar" id="navbar" #elo>
  <div class="container-fluid di-ai-container px-sm-24">
    <div class="d-flex align-items-center w-100">
      <a [routerLink]="'/'">
        <img class="d-none d-md-block" src="../../../../assets/images/svg/Diagnose_color.svg">
        <img class="d-md-none" src="../../../../assets/images/svg/Diagnose_color_lite.svg">
      </a>
      <span class="di-ai-divider-v mx-3"></span>
      <!-- <a href="https://trinetx.com/solutions/live-platform" class="mr-2">
        <img class="d-none d-md-block logo" src="../../../../assets/images/png/TriNetX.png">
        <img class="d-md-none logo" src="../../../../assets/images/png/TriNetX_lite.png">
      </a> -->
      <a href="https://warszawskiinstytut.com.pl" class="mr-2">
        <img class="d-none d-md-block" src="../../../../assets/images/svg/Warszawski_instytut.svg">
        <img class="d-md-none" src="../../../../assets/images/svg/Warszawski_instytut_lite.svg">
      </a>
      <a href="https://www.wum.edu.pl" class="ml-1">
        <img src="../../../../assets/images/svg/WUM.svg">
      </a>
      <span class="flex-grow-1"></span>
      <nav class="d-none d-xl-flex align-items-center">
        <!-- <a routerLink="/" class="mr-3 link">Wyklucz ryzyko choroby</a> -->
        <a routerLink="/" class="mr-3 link">O nas</a>
        <a routerLink="/" class="mr-3 link">Dla lekarzy</a>
        <a [routerLink]="'/login'"
          class="btn btn-account with-icon">Twoje konto</a>
      </nav>

      <button class="navbar-toggler d-xl-none" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
        <i class="di-ai-icon-menu"></i>
      </button>
    </div>
    <nav [ngbCollapse]="isMenuCollapsed" class="d-xl-none">
      <div class="list-group py-2 list-group-flush">
        <a routerLink="/" class="nav-link list-group-item">Wyklucz ryzyko choroby</a>
        <a routerLink="/" class="nav-link list-group-item">O nas</a>
        <a routerLink="/" class="nav-link list-group-item">Dla lekarzy</a>
        <a routerLink="/login" class="nav-link list-group-item">Twoje konto</a>
      </div>
    </nav>
  </div>
</nav>
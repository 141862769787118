import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MainLayoutComponent } from './layouts/main/main-layout.component';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { LiteLayoutComponent } from './layouts/lite/lite-layout.component';
import { NavbarLiteComponent } from './shared/components/navbar-lite/navbar-lite.component';
import { AppToastsComponent } from './shared/components/toast/toast.component';
import { NgxSpinnerModule } from 'ngx-spinner';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlterLayoutComponent } from './layouts/alter/alter-layout.component';

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    LiteLayoutComponent,
    AlterLayoutComponent,
    NavbarComponent,
    NavbarLiteComponent,
    FooterComponent,
    AppToastsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    AppRoutingModule,
    NgxSpinnerModule
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { bodyClasses } from './app.component';
import { LiteLayoutComponent } from './layouts/lite/lite-layout.component';
import { MainLayoutComponent } from './layouts/main/main-layout.component';
import { NotFoundComponent } from './not-found/not-found.component';

const AppRoutes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    children: [
      { path: "", loadChildren: () => import('./home/home.module').then(m => m.HomeModule), pathMatch: "full" },
    ]
  },
  {
    path: "",
    children: [
      { path: "", loadChildren: () => import('./results-interpretation/results-interpretation.module').then(m => m.ResultsInterpretationModule) },
      { path: "", loadChildren: () => import('./personal-research/personal-research.module').then(m => m.PersonalResearchModule) }
    ]
  },
  {
    path: "",
    component: LiteLayoutComponent,
    children: [
      { path: "",loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule) },
      // { path: "", loadChildren: () => import('./user-info/user-info.module').then(m => m.UserInfoModule) },
      { path: '**',  component: NotFoundComponent, data: { bodyClass: bodyClasses.bodyBgPic2 }}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [
    RouterModule,
  ]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './not-found.component.html',
  host: { 'class': 'd-flex flex-grow-1 align-items-center' }
})
export class NotFoundComponent implements OnInit {


  constructor() { }

  async ngOnInit() {

  }

}

import { Component, OnInit, ElementRef, HostListener, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-navbar-lite',
  templateUrl: './navbar-lite.component.html'
})
export class NavbarLiteComponent implements OnInit {

  public isMenuCollapsed = true;

  constructor(public location: Location, private element: ElementRef) {
  }

  @Input()
  theme?: "light" | "dark";

  ngOnInit() {

    // this.onWindowScroll(event);
  }

  // @HostListener("window:scroll", ["$event"])
  // onWindowScroll(e: Event | undefined) {
  //     const navbar : HTMLElement = this.element.nativeElement.children[0];

  //     if (window.pageYOffset > 100) {
  //         navbar.classList.remove("navbar-transparent");
  //         navbar.classList.add("bg-danger");    
  //     }
  //     else {
  //         navbar.classList.add("navbar-transparent");
  //         navbar.classList.remove("bg-danger");
  //     }
  // }
}

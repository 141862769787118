<div class="di-ai-wrapper-lite d-flex flex-column">
  <div class="di-ai-wrapper-bg"></div>
  <app-navbar-lite [theme]="theme"></app-navbar-lite>


  <div class="di-ai-content-wrapper-lite flex-grow-1 d-flex flex-column align-items-center justify-content-center">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>
</div>
import {
  Component,
  OnInit,
  Renderer2,
  HostListener,
  Inject,
  ViewChild,
  ElementRef
} from "@angular/core";
import { Location } from "@angular/common";
import { DOCUMENT } from "@angular/common";
import { NavbarComponent } from "./shared/components/navbar/navbar.component";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { filter } from "rxjs/operators";
import { valueLabelDto } from "./dto/value-label.dto";

export const bodyClasses = {
  bodyBgPic1: 'body-bg-pic-1',
  bodyBgPic2: 'body-bg-pic-2',
  bodyBgPic3: 'body-bg-pic-3',
  bodyBgGray: 'body-bg-gray'
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {

  constructor(

    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,

    private router: Router

 ) { }

  ngOnInit() {  
    this.router.events.pipe(  
        filter(event => event instanceof NavigationEnd),  
      ).subscribe(() => {
        this.cleanBodyClasses();
        const rt = this.getChild(this.activatedRoute);  
        rt.data.subscribe((data: any) => {  
          if (data.bodyClass) {
            this.addBodyClass(data.bodyClass);
          }  
        });  
      });  
  }  

  getChild(activatedRoute: ActivatedRoute): any {  
    if (activatedRoute.firstChild) {  
      return this.getChild(activatedRoute.firstChild);  
    } else {  
      return activatedRoute;  
    }
  }

  cleanBodyClasses() {
    Object.entries(bodyClasses).forEach(([key, value]) => {
      this.renderer.removeClass(document.body, value);
    });
  }

  addBodyClass(className: string) {
    this.renderer.addClass(document.body, className);
  }
}
import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {

  public isMenuCollapsed = true;

  constructor(public location: Location, private element: ElementRef) {
  }

  ngOnInit() {

    // this.onWindowScroll(event);
  }

  // @HostListener("window:scroll", ["$event"])
  // onWindowScroll(e: Event | undefined) {
  //     const navbar : HTMLElement = this.element.nativeElement.children[0];

  //     if (window.pageYOffset > 100) {
  //         navbar.classList.remove("navbar-transparent");
  //         navbar.classList.add("bg-danger");    
  //     }
  //     else {
  //         navbar.classList.add("navbar-transparent");
  //         navbar.classList.remove("bg-danger");
  //     }
  // }
}

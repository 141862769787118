//import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, AfterViewInit } from '@angular/core';
//import { MenuItems } from '../../shared/menu-items/menu-items';


@Component({
  selector: 'app-main-layout',
  templateUrl: 'main-layout.component.html'
})
export class MainLayoutComponent implements AfterViewInit {

  constructor(

  ) {

  }


  ngAfterViewInit() { }
}
//import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, AfterViewInit, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
//import { MenuItems } from '../../shared/menu-items/menu-items';
import { filter } from 'rxjs/operators';  





@Component({
  selector: 'app-lite-layout',
  templateUrl: 'lite-layout.component.html'
})
export class LiteLayoutComponent implements  OnInit {
  previousUrl: string | undefined;

  public theme: "light" | "dark" = "light";
  
  constructor(
    private router: Router,  
    private activatedRoute: ActivatedRoute,  
    private titleService: Title,
    private renderer: Renderer2
  ) {





    // this.router.events
    // .filter(e => e instanceof NavigationEnd)
    // .forEach(e => {
    //   console.log('title', route.root.firstChild.snapshot.data.title);
    // }

      //   this.router.events.pipe(  
      //   filter(event => event instanceof NavigationEnd),  
      //   ).subscribe(() => {  
      //   const rt = this.getChild(this.activatedRoute);  
      //   rt.data.subscribe((data: any) => {  
      //     console.log(data);  
      //     //this.titleService.setTitle(data.title)
      //   });  
      // });  

      // this.router.events
      // .subscribe((event) => {
      //   if (event instanceof NavigationStart) {
      //     if (this.previousUrl) {
      //       // this.renderer.removeClass(document.body, this.previousUrl);
      //     }
      //     let currentUrlSlug = event.url.slice(1)
      //     if (currentUrlSlug) {
      //       console.log(currentUrlSlug);
      //       // this.renderer.addClass(document.body, currentUrlSlug);
      //     }
      //     this.previousUrl = currentUrlSlug;
      //   }
      // });


  }

  ngOnInit() {  
  //   this.router.events.pipe(  
  //       filter(event => event instanceof NavigationEnd),  
  //     ).subscribe(() => {  
  //       const rt = this.getChild(this.activatedRoute);  
  //       rt.data.subscribe((data: any) => {  
  //         console.log(data);  
  //         //this.titleService.setTitle(data.title)
  //       });  
  //     });  

  this.activatedRoute.data.subscribe(data => {
    if (data.theme) {
      this.theme = data.theme;
    }
})
   }  

   
  
  getChild(activatedRoute: ActivatedRoute): any {  
    if (activatedRoute.firstChild) {  
      return this.getChild(activatedRoute.firstChild);  
    } else {  
      return activatedRoute;  
    }  
  
  }  
  
}  